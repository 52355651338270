import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { AxiosError, AxiosResponse } from "axios";
import { ErrorMessage } from "./components/ErrorMessage/ErrorMessage";
import { FriendlyCaptcha } from "./components/FriendlyCaptcha/FriendlyCaptcha";
import { FormField } from "./components/FormField/FormField";
import { FormTextarea } from "./components/FormTextarea/FormTextarea";
import { SubmitButton } from "./components/SubmitButton/SubmitButton";
import { Dropdown } from "./components/Dropdown/Dropdown";
import { Card } from "./components/Card/Card";
import { api } from "../../api/api";
import {
  InlineResponse201,
  InlineResponse400,
  InlineResponse500,
} from "../../services/openapi";
import { FormSuccess } from "./components/FormSuccess/FormSuccess";
import { Title } from "./components/Title/Title";
import { Trans, useTranslation } from "react-i18next";
import { FormFileUpload } from "./components/FormFileUpload/FormFileUpload";
import { ReklamationItem } from "../../api/api.d";
import { focusHeader } from "../Header/Header";

type responseDataType = {
  data?: {
    message?: string;
    errors?: object;
  };
};

interface FormProperties {
  interceptOnSubmit?: Function;
}

interface ReklamationItemExtended extends ReklamationItem {
  referenztyp?: "orderNumber" | "invoiceNumber" | "deliverynoteNumber";
  referenznummer?: string;
}

interface FormError {
  type?: string;
  message?: string;
}

const isSuccess = (responseData: responseDataType) =>
  responseData && responseData.data && responseData.data.message;

const Form = ({
  interceptOnSubmit = (data: ReklamationItem) => data,
}: FormProperties) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<ReklamationItemExtended>({
    mode: "onBlur",
    defaultValues: { referenztyp: "invoiceNumber" },
  });

  const [responseData, setResponseData] = useState(Object);
  const [captcha, setCaptcha] = useState(String);
  const [resetCaptcha, setResetCaptcha] = useState(false);
  const [error, setError] = useState<FormError>(Object);
  const [sending, setSending] = useState(Boolean);
  const [files, setFiles] = useState<Array<File>>([]);

  const { t } = useTranslation();

  function handleError(error: AxiosError) {
    setCaptcha("");
    setResetCaptcha(true);
    let errorMessage = t("error.validation.general");
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.type
    ) {
      errorMessage = t(error.response.data.message) + " " + t("error.tryAgain");
    }
    setError({
      type: error.response?.data.type,
      message: errorMessage,
    });
    setResetCaptcha(false);
  }

  const onSubmit = (data: ReklamationItemExtended) => {
    // Set state to sending
    setSending(true);
    // Reset error state
    setError({});
    // Pre-process form data for submission
    // Re-format the data so it matches the expected type
    if (data["referenztyp"] === "invoiceNumber") {
      delete data["orderNumber"];
      delete data["deliverynoteNumber"];
    } else if (data["referenztyp"] === "orderNumber") {
      delete data["invoiceNumber"];
      delete data["deliverynoteNumber"];
    } else if (data["referenztyp"] === "deliverynoteNumber") {
      delete data["invoiceNumber"];
      delete data["orderNumber"];
    }
    delete data["referenztyp"];

    // Delete empty or null keys from our form values
    for (const key in data) // @ts-ignore
      (data[key] == null || data[key] === "") && delete data[key];
    data["frcCaptchaSolution"] = captcha;

    data = interceptOnSubmit(data);

    // @ts-ignore
    files.map((file, index) => (data[`file${index}`] = file));

    api
      .submitPost(interceptOnSubmit(data))
      .then((response: AxiosResponse<InlineResponse201>) => {
        setResponseData(response);
        setSending(false);
      })
      .catch((error: AxiosError<InlineResponse400 | InlineResponse500>) => {
        handleError(error);
        setSending(false);
      });
  };

  const refreshForm = (resetData: boolean = true) => {
    setCaptcha("");
    focusHeader();
    setResetCaptcha(true);
    setResponseData({});
    if (resetData) {
      reset();
      setFiles([]);
    }
    setResetCaptcha(false);
  };

  const watchReferenztyp = watch("referenztyp");

  const handleCaptchaError = (error: string) => {
    console.error(error);
  };

  if (isSuccess(responseData)) {
    return <FormSuccess resetForm={refreshForm} />;
  }

  return (
    <div className="px-[13px]">
      <Title title={t("header.title")} subtitle={t("header.subtitle")} />
      <form
        autoComplete="on"
        onSubmit={handleSubmit(onSubmit)}
        className="max-w-s sm:max-w-m lg:max-w-l m-auto py-10 mt-[10px] flex flex-col gap-[40px] md:gap-[50px]"
        data-testid={"form"}
      >
        <Card title={t("label.cardTitles.reference")}>
          <p className="mt-[30px]">
            <Trans>label.reference.subtitle</Trans>
          </p>
          <Dropdown
            name={"referenztyp"}
            label={"Art der Referenzierung"}
            register={register}
            errors={errors}
            options={{
              required: t("label.validation.required"),
            }}
            choices={[
              {
                value: "invoiceNumber",
                label: t("label.reference.invoiceNumber"),
              },
              {
                value: "orderNumber",
                label: t("label.reference.orderNumber"),
              },
              {
                value: "deliverynoteNumber",
                label: t("label.reference.deliverynoteNumber"),
              },
            ]}
          />
          {watchReferenztyp === "invoiceNumber" && (
            <FormField
              name={"invoiceNumber"}
              label={t("label.reference.invoiceNumber")}
              options={{
                required: t("label.validation.required"),
                pattern: {
                  value: /^\d{11,16}$/g,
                  message: t("label.validation.minMaxLength", {
                    min: 11,
                    max: 16,
                  }),
                },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue("orderNumber", e.target.value);
                  setValue("deliverynoteNumber", e.target.value);
                },
              }}
              register={register}
              errors={errors}
            />
          )}
          {watchReferenztyp === "orderNumber" && (
            <FormField
              name={"orderNumber"}
              label={"Auftragsnummer"}
              options={{
                required: t("label.validation.required"),
                pattern: {
                  value: /^\d{11,12}$/g,
                  message: t("label.validation.minMaxLength", {
                    min: 11,
                    max: 16,
                  }),
                },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue("invoiceNumber", e.target.value);
                  setValue("deliverynoteNumber", e.target.value);
                },
              }}
              register={register}
              errors={errors}
            />
          )}
          {watchReferenztyp === "deliverynoteNumber" && (
            <FormField
              name={"deliverynoteNumber"}
              label={t("label.reference.deliverynoteNumber")}
              options={{
                required: t("label.validation.required"),
                pattern: {
                  value: /^\d{11,16}$/,
                  message: t("label.validation.minMaxLength", {
                    min: 11,
                    max: 16,
                  }),
                },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue("invoiceNumber", e.target.value);
                  setValue("orderNumber", e.target.value);
                },
              }}
              register={register}
              errors={errors}
            />
          )}
        </Card>
        <Card title={t("label.cardTitles.reklamation")}>
          <Dropdown
            name={"reason"}
            label={t("label.reason")}
            options={{
              required: t("label.validation.required"),
            }}
            choices={[
              { value: "Rechnung", label: t("label.reasons.invoice") },
              {
                value: "Dienstleistung",
                label: t("label.reasons.service"),
              },
              {
                value: "Container/Behälter",
                label: t("label.reasons.container"),
              },
              { value: "Service", label: t("label.reasons.support") },
              { value: "Sonstiges", label: t("label.reasons.other") },
            ]}
            register={register}
            errors={errors}
          />
          <FormTextarea
            name={"message"}
            label={t("label.yourMessage")}
            options={{
              required: t("label.validation.required"),
              maxLength: {
                value: 4000,
                message: t("label.validation.maxLength", { max: 4000 }),
              },
            }}
            register={register}
            errors={errors}
          />
          <FormFileUpload files={files} setFiles={setFiles} t={t} />
        </Card>
        <div>
          <Card title={t("label.cardTitles.contact")}>
            <Dropdown
              name={"formOfAddress"}
              label={t("label.formOfAddress")}
              register={register}
              errors={errors}
              options={{
                required: t("label.validation.required"),
              }}
              choices={[
                {
                  value: "Keine Angabe",
                  label: t("label.formOfAddresses.other"),
                },
                { value: "Herr", label: t("label.formOfAddresses.m") },
                { value: "Frau", label: t("label.formOfAddresses.f") },
              ]}
            />
            <FormField
              autocomplete="given-name"
              name={"firstName"}
              label={t("label.firstName")}
              options={{
                required: t("label.validation.required"),
                maxLength: {
                  value: 255,
                  message: t("label.validation.maxLength", { max: 255 }),
                },
              }}
              register={register}
              errors={errors}
            />
            <FormField
              autocomplete="family-name"
              name={"lastName"}
              label={t("label.lastName")}
              options={{
                required: t("label.validation.required"),
                maxLength: {
                  value: 255,
                  message: t("label.validation.maxLength", { max: 255 }),
                },
              }}
              register={register}
              errors={errors}
            />
            <FormField
              autocomplete="email"
              name={"email"}
              label={t("label.email")}
              options={{
                required: t("label.validation.required"),
                pattern: {
                  value: /^.*@.*\..*$/g,
                  message: t("label.validation.email"),
                },
              }}
              register={register}
              errors={errors}
            />
            <FormField
              autocomplete="tel"
              name={"phone"}
              label={t("label.phone")}
              options={{
                maxLength: {
                  value: 30,
                  message: t("label.validation.maxLength", { max: 30 }),
                },
              }}
              register={register}
              errors={errors}
            />
            <FormField
              autocomplete="organization"
              name={"company"}
              label={t("label.company")}
              options={{
                maxLength: {
                  value: 255,
                  message: t("label.validation.maxLength", { max: 255 }),
                },
              }}
              register={register}
              errors={errors}
            />
          </Card>
          <p className="mt-[10px] text-base-gray text-center">
            {t("label.requiredHint")}
          </p>
        </div>
        <div className="flex flex-col w-full justify-around gap-[20px]">
          <p className="text-center">
            {t("label.dataProtection.hint") + " "}
            <a href="https://www.lobbe.de/datenschutz/">
              {t("label.dataProtection.link")}
            </a>
            .
          </p>
          {sending ? (
            <button
              className="animate-pulse mx-auto bg-gray-500 disabled:text-white text-white w-full md:w-[214px]"
              data-testid={"sending-message"}
            >
              {`${t("label.sending")}`}
            </button>
          ) : (
            <SubmitButton disabled={captcha === ""} />
          )}
          {error.message ? (
            <div className="text-center mx-auto max-w-[500px]">
              <ErrorMessage message={error.message} name="server" />
            </div>
          ) : null}
          <FriendlyCaptcha
            onDone={(solution: string) => setCaptcha(solution)}
            onError={handleCaptchaError}
            reset={resetCaptcha}
          />
        </div>
      </form>
    </div>
  );
};

export default Form;
