/* tslint:disable */
/* eslint-disable */
/**
 * ORDS generated API for Reklamationstool Test
 * Reklamation Rest Test Endpoint
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface InlineResponse201
 */
export interface InlineResponse201 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse201
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse400
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse400
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface InlineResponse500
 */
export interface InlineResponse500 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse500
     */
    'message': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new reklamation
         * @param {string} reason 
         * @param {string} message 
         * @param {string} email 
         * @param {string} formOfAddress 
         * @param {string} firstName 
         * @param {string} lastName 
         * @param {string} [phone] 
         * @param {string} [company] 
         * @param {string} [orderNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [deliverynoteNumber] 
         * @param {string} [frcCaptchaSolution] 
         * @param {any} [file0] 
         * @param {any} [file1] 
         * @param {any} [file2] 
         * @param {any} [file3] 
         * @param {any} [file4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPost: async (reason: string, message: string, email: string, formOfAddress: string, firstName: string, lastName: string, phone?: string, company?: string, orderNumber?: string, invoiceNumber?: string, deliverynoteNumber?: string, frcCaptchaSolution?: string, file0?: any, file1?: any, file2?: any, file3?: any, file4?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reason' is not null or undefined
            assertParamExists('submitPost', 'reason', reason)
            // verify required parameter 'message' is not null or undefined
            assertParamExists('submitPost', 'message', message)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('submitPost', 'email', email)
            // verify required parameter 'formOfAddress' is not null or undefined
            assertParamExists('submitPost', 'formOfAddress', formOfAddress)
            // verify required parameter 'firstName' is not null or undefined
            assertParamExists('submitPost', 'firstName', firstName)
            // verify required parameter 'lastName' is not null or undefined
            assertParamExists('submitPost', 'lastName', lastName)
            const localVarPath = `/reklamation.submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (reason !== undefined) { 
                localVarFormParams.append('reason', reason as any);
            }
    
            if (message !== undefined) { 
                localVarFormParams.append('message', message as any);
            }
    
            if (email !== undefined) { 
                localVarFormParams.append('email', email as any);
            }
    
            if (phone !== undefined) { 
                localVarFormParams.append('phone', phone as any);
            }
    
            if (company !== undefined) { 
                localVarFormParams.append('company', company as any);
            }
    
            if (formOfAddress !== undefined) { 
                localVarFormParams.append('formOfAddress', formOfAddress as any);
            }
    
            if (firstName !== undefined) { 
                localVarFormParams.append('firstName', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.append('lastName', lastName as any);
            }
    
            if (orderNumber !== undefined) { 
                localVarFormParams.append('orderNumber', orderNumber as any);
            }
    
            if (invoiceNumber !== undefined) { 
                localVarFormParams.append('invoiceNumber', invoiceNumber as any);
            }
    
            if (deliverynoteNumber !== undefined) { 
                localVarFormParams.append('deliverynoteNumber', deliverynoteNumber as any);
            }
    
            if (frcCaptchaSolution !== undefined) { 
                localVarFormParams.append('frcCaptchaSolution', frcCaptchaSolution as any);
            }
    
            if (file0 !== undefined) { 
                localVarFormParams.append('file0', file0 as any);
            }
    
            if (file1 !== undefined) { 
                localVarFormParams.append('file1', file1 as any);
            }
    
            if (file2 !== undefined) { 
                localVarFormParams.append('file2', file2 as any);
            }
    
            if (file3 !== undefined) { 
                localVarFormParams.append('file3', file3 as any);
            }
    
            if (file4 !== undefined) { 
                localVarFormParams.append('file4', file4 as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new reklamation
         * @param {string} reason 
         * @param {string} message 
         * @param {string} email 
         * @param {string} formOfAddress 
         * @param {string} firstName 
         * @param {string} lastName 
         * @param {string} [phone] 
         * @param {string} [company] 
         * @param {string} [orderNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [deliverynoteNumber] 
         * @param {string} [frcCaptchaSolution] 
         * @param {any} [file0] 
         * @param {any} [file1] 
         * @param {any} [file2] 
         * @param {any} [file3] 
         * @param {any} [file4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitPost(reason: string, message: string, email: string, formOfAddress: string, firstName: string, lastName: string, phone?: string, company?: string, orderNumber?: string, invoiceNumber?: string, deliverynoteNumber?: string, frcCaptchaSolution?: string, file0?: any, file1?: any, file2?: any, file3?: any, file4?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse201>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitPost(reason, message, email, formOfAddress, firstName, lastName, phone, company, orderNumber, invoiceNumber, deliverynoteNumber, frcCaptchaSolution, file0, file1, file2, file3, file4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new reklamation
         * @param {string} reason 
         * @param {string} message 
         * @param {string} email 
         * @param {string} formOfAddress 
         * @param {string} firstName 
         * @param {string} lastName 
         * @param {string} [phone] 
         * @param {string} [company] 
         * @param {string} [orderNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [deliverynoteNumber] 
         * @param {string} [frcCaptchaSolution] 
         * @param {any} [file0] 
         * @param {any} [file1] 
         * @param {any} [file2] 
         * @param {any} [file3] 
         * @param {any} [file4] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPost(reason: string, message: string, email: string, formOfAddress: string, firstName: string, lastName: string, phone?: string, company?: string, orderNumber?: string, invoiceNumber?: string, deliverynoteNumber?: string, frcCaptchaSolution?: string, file0?: any, file1?: any, file2?: any, file3?: any, file4?: any, options?: any): AxiosPromise<InlineResponse201> {
            return localVarFp.submitPost(reason, message, email, formOfAddress, firstName, lastName, phone, company, orderNumber, invoiceNumber, deliverynoteNumber, frcCaptchaSolution, file0, file1, file2, file3, file4, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for submitPost operation in DefaultApi.
 * @export
 * @interface DefaultApiSubmitPostRequest
 */
export interface DefaultApiSubmitPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitPost
     */
    readonly reason: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitPost
     */
    readonly message: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitPost
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitPost
     */
    readonly formOfAddress: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitPost
     */
    readonly firstName: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitPost
     */
    readonly lastName: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitPost
     */
    readonly phone?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitPost
     */
    readonly company?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitPost
     */
    readonly orderNumber?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitPost
     */
    readonly invoiceNumber?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitPost
     */
    readonly deliverynoteNumber?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSubmitPost
     */
    readonly frcCaptchaSolution?: string

    /**
     * 
     * @type {any}
     * @memberof DefaultApiSubmitPost
     */
    readonly file0?: any

    /**
     * 
     * @type {any}
     * @memberof DefaultApiSubmitPost
     */
    readonly file1?: any

    /**
     * 
     * @type {any}
     * @memberof DefaultApiSubmitPost
     */
    readonly file2?: any

    /**
     * 
     * @type {any}
     * @memberof DefaultApiSubmitPost
     */
    readonly file3?: any

    /**
     * 
     * @type {any}
     * @memberof DefaultApiSubmitPost
     */
    readonly file4?: any
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Create a new reklamation
     * @param {DefaultApiSubmitPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public submitPost(requestParameters: DefaultApiSubmitPostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).submitPost(requestParameters.reason, requestParameters.message, requestParameters.email, requestParameters.formOfAddress, requestParameters.firstName, requestParameters.lastName, requestParameters.phone, requestParameters.company, requestParameters.orderNumber, requestParameters.invoiceNumber, requestParameters.deliverynoteNumber, requestParameters.frcCaptchaSolution, requestParameters.file0, requestParameters.file1, requestParameters.file2, requestParameters.file3, requestParameters.file4, options).then((request) => request(this.axios, this.basePath));
    }
}


