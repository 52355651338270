export const InputLabel = ({
  label,
  required,
}: {
  label: string;
  required?: boolean;
}) => {
  return (
    <label className="text-base-gray text-label block mt-[30px] mb-[10px]">
      {`${label} ${required ? "*" : ""}`}
    </label>
  );
};
