import React from "react";
import { InputLabel } from "../InputLabel/InputLabel";
import { TextFieldProps } from "../FormField/FormField";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

export const FormTextarea = ({
  name,
  label,
  options = {},
  errors,
  register,
  type,
}: TextFieldProps) => {
  return (
    <>
      <InputLabel label={label} required={!!options?.required} />
      <textarea
        aria-label={label}
        aria-required={!!options?.required}
        inputMode="text"
        className="h-[176px] py-2 px-4 w-full"
        {...register(name, options)}
        {...(type && { type })}
        data-testid={name}
      />
      <ErrorMessage message={errors[name]?.message} name={name} />
    </>
  );
};
