import React from "react";
import { FieldErrors, RegisterOptions } from "react-hook-form";
import { InputLabel } from "../InputLabel/InputLabel";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { TFunctionResult } from "i18next";

interface Options extends Omit<RegisterOptions, "required"> {
  required?: null | TFunctionResult | RegisterOptions["required"];
}
export interface TextFieldProps {
  name: string;
  label: string;
  options?: Options;
  errors: FieldErrors;
  register: Function;
  type?: string;
  autocomplete?: string;
}

export const FormField = ({
  name,
  label,
  options = {},
  errors,
  register,
  type,
  autocomplete,
}: TextFieldProps) => {
  return (
    <>
      <InputLabel label={label} required={!!options?.required} />
      <input
        aria-label={label}
        aria-required={!!options?.required}
        autoComplete={autocomplete}
        className="py-2 px-4 w-full text-black"
        {...register(name, options)}
        {...(type && { type })}
        data-testid={name}
      />
      <ErrorMessage message={errors[name]?.message} name={name} />
    </>
  );
};
