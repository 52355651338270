import React from "react";
import { ReactComponent as Trash } from "../../../../assets/icons/trash.svg";
import { ReactComponent as PDF } from "../../../../assets/icons/document-text.svg";
import { ReactComponent as Photograph } from "../../../../assets/icons/photograph.svg";

interface FileCardProps {
  files: Array<File>;
  setFiles: Function;
  file: File;
  fileIndex: string;
}

export const FileCard = ({
  files,
  setFiles,
  file,
  fileIndex,
}: FileCardProps) => {
  const isPdf = file.name.endsWith(".pdf");

  return (
    <div
      className="mx-auto bg-white rounded shadow flex items-center my-4"
      data-testid={fileIndex}
    >
      <div className="shrink-0 p-5">{isPdf ? <PDF /> : <Photograph />}</div>
      <div className="flex-1 truncate font-semibold">{file.name}</div>
      <div className="shrink-0">
        <button
          className="p-5"
          onClick={(e) => {
            e.preventDefault();
            setFiles(files.filter((f) => f !== file));
          }}
        >
          <Trash />
        </button>
      </div>
    </div>
  );
};
