import React from "react";

interface CardProps {
  title: string;
  children?: React.ReactNode;
}

export const Card = ({ title, children }: CardProps) => {
  return (
    <div className="px-[20px] py-[30px] sm:p-[50px] bg-light-gray">
      <h3 className={"card-title"}>{title}</h3>
      <div className="childrenContainer">{children}</div>
    </div>
  );
};
