import React from "react";

// Define interface for component props/api:
export interface DropZoneProps {
  onDragStateChange?: (isDragActive: boolean) => void;
  onDrag?: () => void;
  onDragIn?: () => void;
  onDragOut?: () => void;
  onDrop?: Function;
  onFilesDrop?: Function;
  files: Array<File>;
}

export const DropZone = React.memo(
  (props: React.PropsWithChildren<DropZoneProps>) => {
    const {
      onDragStateChange,
      onFilesDrop,
      onDrag,
      onDragIn,
      onDragOut,
      onDrop,
    } = props;

    // Create state to keep track when dropzone is active/non-active:
    const [isDragActive, setIsDragActive] = React.useState(false);
    // Prepare ref for dropzone element:
    const dropZoneRef = React.useRef<null | HTMLDivElement>(null);

    // Create handler for dragenter event:
    const handleDragIn = React.useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        onDragIn?.();

        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
          setIsDragActive(true);
        }
      },
      [onDragIn]
    );

    // Create handler for dragleave event:
    const handleDragOut = React.useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        onDragOut?.();

        setIsDragActive(false);
      },
      [onDragOut]
    );

    // Create handler for dragover event:
    const handleDrag = React.useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();

        onDrag?.();
        if (!isDragActive) {
          setIsDragActive(true);
        }
      },
      [isDragActive, onDrag]
    );

    // Create handler for drop event:
    const handleDrop = React.useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();

        setIsDragActive(false);
        onDrop?.(event);

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
          onFilesDrop?.(event.dataTransfer.files);
          event.dataTransfer.clearData();
        }
      },
      [onDrop, onFilesDrop]
    );

    // Observe active state and emit changes:
    React.useEffect(() => {
      onDragStateChange?.(isDragActive);
    }, [isDragActive, onDragStateChange]);

    // Render <div> with ref and children:
    return (
      <div
        className={`mt-10 w-full bg-white h-[150px] flex items-end justify-center border-2 border-base-gray border-dashed hover:bg-light-gray ${
          isDragActive ? "bg-light-gray" : "bg-white"
        }`}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        ref={dropZoneRef}
        data-testid="dropzone"
      >
        {props.children}
      </div>
    );
  }
);

DropZone.displayName = "DropZone";
