import React, { useEffect } from "react";
import { Card } from "../Card/Card";
import { Title } from "../Title/Title";
import { Trans, useTranslation } from "react-i18next";
import { focusHeader } from "../../../Header/Header";

interface FunctionProps {
  resetForm: () => void;
}

export const FormSuccess = ({ resetForm }: FunctionProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    focusHeader();
  }, []);
  return (
    <div className="px-[13px]" data-testid="success-message">
      <Title
        title={t("success.header.title")}
        subtitle={t("success.header.subtitle")}
      />
      <div className="max-w-s sm:max-w-m lg:max-w-l m-auto  mt-10 flex flex-col space-y-card">
        <Card title={t("success.card.title")}>
          <div className="text-black mb-[18px] mt-[30px]">
            <Trans>success.card.text</Trans>
          </div>
        </Card>
        <div className="w-full justify-center m-auto mt-10 flex flex-col">
          <a href="https://lobbe.de" className="w-full md:w-[214px] m-auto">
            <button
              data-testid="continue-button"
              className="bg-red-lobbe text-white hover:bg-base-gray w-full "
            >
              {t("success.back")}
            </button>
          </a>
          <div
            role="link"
            onClick={resetForm}
            data-testid="reset-button"
            className="h-min w-min m-auto whitespace-nowrap cursor-pointer text-black underline mt-[30px] mb-[60px] md:mb-[80px]"
          >
            {t("success.repeatForm")}
          </div>
        </div>
      </div>
    </div>
  );
};
