import Logo from "../../assets/lobbe-logo2.png";
import { useTranslation } from "react-i18next";
export const focusHeader = () => {
  window.scrollTo({ top: 0 });
};

export const Header = () => {
  const { t } = useTranslation();
  return (
    <div
      id="header"
      className="h-[120px] bg-white  pl-[13px] pt-[46px] md:pl-[120px] md:pt-[40px]"
    >
      <a href="https://lobbe.de" aria-label={t("logo.linkLabel")}>
        <img
          className="w-[120px] lg:w-[200px] "
          src={Logo}
          alt={t("logo.imageAlt")}
        />
      </a>
    </div>
  );
};
