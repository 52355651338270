import "./Footer.css";

export const Footer = () => {
  return (
    <div className="text-white bg-dark-gray">
      <div className="bg-base-gray">
        <div
          id="footerContainer"
          className="max-w-l mx-auto py-[50px] px-[13px] sm:px-[40px] flex flex-col sm:flex-row gap-[23px] justify-between"
        >
          <div id="address" className="w-full">
            <h3 className="mb-[21px]">Adresse</h3>
            <hr className="mb-[32px]" />
            <p className="text-label">Lobbe Holding GmbH</p>
            <p>
              Bernhard-Hülsmann-Weg 2<br />
              58644 Iserlohn
            </p>
            <p>
              Telefon: +49 2371 8880
              <br />
              Telefax: +49 2371 888108
            </p>
          </div>
          <div id="telnumbers" className="w-full">
            <h3 className="mb-[21px]">Rufnummern</h3>
            <hr className="mb-[32px]" />
            <label>Servicerufnummer</label>
            <p>+49 2371 4340</p>
            <label>Rohrreinigung & Abflussservice</label>
            <p>+49 800 00 562 23</p>
            <label>Ölwehr, Ölalarm & Havarien</label>
            <p>+49 1805 600 500</p>
          </div>
        </div>
      </div>
      <div
        id="imprintContainer"
        className="w-[237px] ml-auto mr-[13px] sm:mr-[40px] py-[13px] flex justify-between"
      >
        <a href="https://www.lobbe.de/impressum/">Impressum</a>
        <span>/</span>
        <a href="https://www.lobbe.de/datenschutz/">Datenschutz</a>
      </div>
    </div>
  );
};
