import React from "react";
import { useTranslation } from "react-i18next";

export const SubmitButton = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  return (
    <button
      className="bg-red-lobbe disabled:bg-gray-500 hover:bg-base-gray w-full md:w-[214px] mx-auto"
      type={"submit"}
      data-testid={"submit"}
      disabled={disabled}
    >
      {`${t(disabled ? "label.pleaseWait" : "label.submit")}`}
    </button>
  );
};
