import React from "react";
import { Trans } from "react-i18next";

interface HeaderProps {
  title: string;
  subtitle: string;
}

export const Title = ({ title, subtitle }: HeaderProps) => (
  <div className="max-w-s sm:max-w-m lg:max-w-l mx-auto sm:flex-col">
    <h1
      className="text-base-gray text-[2.5rem] lg:text-[4rem]"
      data-testid="header-title"
    >
      {title}
    </h1>
    <p className={"mt-[20px]"} data-testid="header-subtitle">
      <Trans>{subtitle}</Trans>
    </p>
  </div>
);
