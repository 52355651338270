import React, { useEffect, useRef } from "react";
import { WidgetInstance } from "friendly-challenge";

interface FriendlyCaptchaInterface {
  onDone: Function;
  onError: Function;
  reset: boolean;
}
export const FriendlyCaptcha = ({
  onDone,
  onError,
  reset,
}: FriendlyCaptchaInterface) => {
  const container = useRef<HTMLDivElement>(null);
  const widget = useRef<WidgetInstance>();

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        language: "de",
        puzzleEndpoint: process.env.REACT_APP_CAPTCHA_ENDPOINT,
        sitekey: process.env.REACT_APP_CAPTCHA_SITEKEY,
        startMode: "auto",
        doneCallback: (solution: string) => onDone(solution),
        errorCallback: (error: string) => onError(error),
      });
    }
    return () => {
      if (reset && widget.current !== undefined && widget.current.reset)
        widget.current.reset();
    };
  });

  return <div ref={container} className="frc-captcha mx-auto" />;
};
