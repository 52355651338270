import React from "react";

export const ErrorMessage = ({
  message,
  name,
}: {
  name: string;
  message?: string;
}) =>
  message ? (
    <p
      data-testid={`${name}-alert`}
      role="alert"
      className={`${
        name === "server" ? "text-lg" : "text-normal"
      } text-red-lobbe`}
    >
      {message}
    </p>
  ) : null;
