import React, { useCallback, useState } from "react";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { FileCard } from "../FileCard/FileCard";
import { InputLabel } from "../InputLabel/InputLabel";
import { DropZone } from "./components/DropZone";
import { Trans } from "react-i18next";

export interface FileUploadProps {
  files: Array<File>;
  setFiles: Function;
  t: Function;
}

export const FormFileUpload = ({ files, setFiles, t }: FileUploadProps) => {
  const [filesError, setFilesError] = useState(String);
  const addFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent) => {
      function isDragEvent(
        event: React.DragEvent | React.ChangeEvent<HTMLInputElement>
      ): event is React.DragEvent {
        return (event as React.DragEvent).dataTransfer?.files !== undefined;
      }

      let filesToUpload: FileList | null;
      if (isDragEvent(e)) {
        filesToUpload = e.dataTransfer?.files;
      } else {
        filesToUpload = e.target?.files;
      }

      const fileValidationErrors = {
        fileCount: "",
        fileSize: "",
        fileDuplicate: "",
        fileExtension: "",
      };

      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf|\.svg)$/i;

      if (filesToUpload) {
        if (files.length + filesToUpload.length < 6) {
          Array.from(filesToUpload).forEach((file) => {
            if (file.size < 10000000) {
              if (
                files.every((existingFile) => existingFile.name !== file.name)
              ) {
                if (allowedExtensions.exec(file.name)) {
                  setFiles((files: Array<File>) => [...files, file]);
                } else {
                  fileValidationErrors["fileExtension"] = t(
                    "label.validation.fileExtension"
                  );
                }
              } else {
                fileValidationErrors["fileDuplicate"] = t(
                  "label.validation.fileDuplicate"
                );
              }
            } else {
              fileValidationErrors["fileSize"] = t("label.validation.fileSize");
            }
          });
        } else {
          fileValidationErrors["fileCount"] = t("label.validation.fileCount");
        }
      }
      // This does also clears previous errors if none occurred in this run.
      setFilesError(
        Object.values(fileValidationErrors)
          .filter((msg) => msg)
          .join(" ")
      );
    },
    [files, setFiles, t]
  );

  return (
    <>
      <div>
        <InputLabel label={t("label.fileUpload.headline")} />
        <InputLabel label={t("label.fileUpload.subline")} />
      </div>
      {files.length < 5 && (
        <DropZone onDrop={addFile} files={files}>
          <div className="mb-10">
            <p className="text-sm mb-7 font-light text-base-gray text-center items-start">
              <Trans>label.fileUpload.dropzone</Trans>
            </p>
            <label className="bg-base-gray text-white py-4 px-6">
              <span className="mt-2 text-base leading-normal truncate font-light">
                {t("label.fileUpload.button")}
              </span>
              <input
                className="hidden"
                onChange={addFile}
                type="file"
                data-testid={"file-input"}
                accept="image/*,.pdf"
                multiple
              />
            </label>
          </div>
        </DropZone>
      )}

      <ErrorMessage message={filesError} name={"file-input"} />
      <div className="mt-7">
        {files.map((file, index) => {
          return (
            <FileCard
              fileIndex={`file${index}`}
              key={`file${index}`}
              files={files}
              file={file}
              setFiles={setFiles}
            />
          );
        })}
      </div>
    </>
  );
};
