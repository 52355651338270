import React from "react";
import { InputLabel } from "../InputLabel/InputLabel";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { TextFieldProps } from "../FormField/FormField";

interface Choice {
  value: string | number;
  label: string;
}

interface DropdownProps extends TextFieldProps {
  choices: Array<Choice>;
}

export const Dropdown = ({
  name,
  label,
  options = {},
  errors,
  register,
  choices,
}: DropdownProps) => {
  return (
    <>
      <InputLabel label={label} required={!!options?.required} />
      <select
        aria-label={label}
        aria-required={!!options?.required}
        className="py-2 px-4 w-full bg-white"
        {...register(name, options)}
        data-testid={name}
      >
        {choices.map(({ value, label }, i) => {
          return (
            <option key={i} value={value}>
              {label}
            </option>
          );
        })}
      </select>
      <ErrorMessage message={errors[name]?.message} name={name} />
    </>
  );
};
